import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  loginToken: any;
  userDetail: any;
  constructor(private cookieService: SsrCookieService) {
    this.setToken();
  }

  setToken() {

    if (this.cookieService.get('admin_login_token')) {
      this.cookieService.set("lang", 'en');
      this.loginToken = this.cookieService.get('admin_login_token');
    } else if (this.cookieService.get('login_token')) {
      this.loginToken = this.cookieService.get('login_token');
    }
    if (this.loginToken) {
      this.setUserData();
    }
  }

  setUserData() {
    this.userDetail = JSON.parse(atob(this.loginToken.split(".")[1]));
  }

  get getUserData() {
    return this.userDetail;
  }

  get getToken() {
    return this.loginToken ? this.loginToken : null;
  }
}