import { NgModule } from '@angular/core';

import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
    imports: [
        VerticalLayout1Module,
        MatProgressSpinnerModule,
        MatProgressBarModule
    ],
    exports: [
        VerticalLayout1Module,
        ProgressSpinnerComponent
    ],
    declarations: [ProgressSpinnerComponent]
})
export class LayoutModule {
}
