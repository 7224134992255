import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, } from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { AppComponent } from "app/app.component";
import { fuseConfig } from "app/fuse-config";
import { LayoutModule } from "app/layout/layout.module";
import "hammerjs";
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { HttpIntercepterService } from "./core/intercepter/http-intercepter.service";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { provideMessaging, getMessaging } from "@angular/fire/messaging";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAnalytics, getAnalytics, ScreenTrackingService } from '@angular/fire/analytics';

const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./main/apps/apps.module").then((m) => m.AppsModule),
    },
    {
        path: "**",
        redirectTo: "",
    },
];

let firebaseConfig = {
    apiKey: "AIzaSyD5JJ0HyYAtU6PlfD-OZW582KHkBmV3KMY",
    authDomain: "heperplace.firebaseapp.com",
    databaseURL: "https://heperplace.firebaseio.com",
    projectId: "heperplace",
    storageBucket: "heperplace.appspot.com",
    messagingSenderId: "679485929204",
    appId: "1:679485929204:web:63741e05946a21a16510a1",
    measurementId: "G-1XXQQEXBDL"
};
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        provideFirebaseApp(() => initializeApp(firebaseConfig)),
        provideMessaging(() => getMessaging()),
        provideAnalytics(() => getAnalytics()),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        LayoutModule], providers: [
        ScreenTrackingService,
        SsrCookieService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpIntercepterService, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
