import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TokenStorageService } from '../token-storage.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpIntercepterService implements HttpInterceptor {

  constructor(private tokenStorageService: TokenStorageService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenStorageService.getToken;
    if (token) {
      const requestObj = req = req.clone({
        setHeaders: { Authorization: `${token}` }
      });
      return next.handle(requestObj)
        .pipe(
          catchError(error => {
            return throwError(error);
          })
        );
    }
  }
}
